













:root, .x1v5t77g{--x12r3x38:#1c1d1f;--x22fgde:white;--x1ros7t5:rgba(255, 255, 255, 0.6);--xo8e344:#1f2023;}
.x10fa4te:not(#\#){background:#27282b}
.x1md70p1:not(#\#){background:transparent}
.x1wty727:not(#\#){border:0}
.xfj9a5l:not(#\#){border:1px solid transparent}
.x1gs6z28:not(#\#){border:none}
.xln7xf2:not(#\#){font:inherit}
.x1ghz6dp:not(#\#){margin:0}
.xtj3y72:not(#\#){margin:16px}
.x42y017:not(#\#){margin:1rem}
.x18jrek7:not(#\#){padding:0 16px 12px}
.x1xk1jr8:not(#\#){padding:0 16px}
.x1xq1gxn:not(#\#){padding:0 8px}
.x1717udv:not(#\#){padding:0}
.x1iwkndl:not(#\#){padding:12px 16px}
.xrt3fh9:not(#\#){padding:40px 20px 0}
@media screen and (min-width: 700px){.x1u4ax6f.x1u4ax6f:not(#\#){margin:40px}}
@media screen and (min-width: 700px){.x1t4y81h.x1t4y81h:not(#\#){padding:12px 20px}}
@media screen and (min-width: 700px){.x1g10azx.x1g10azx:not(#\#){padding:20px 20px}}
.x1q1rhgw:not(#\#):not(#\#){border-bottom:1px solid #303236}
.x12oqio5:not(#\#):not(#\#){border-radius:4px}
.xou54vl:not(#\#):not(#\#){gap:16px}
.x1c1vhfx:not(#\#):not(#\#){gap:20px}
.x1295hen:not(#\#):not(#\#){grid-template-areas:"meta" "image"}
.x1b6ykf0:not(#\#):not(#\#){outline:0}
.x1a2a7pz:not(#\#):not(#\#){outline:none}
.x1lhehjl:not(#\#):not(#\#){place-self:stretch}
.x1hl2dhg:not(#\#):not(#\#){text-decoration:none}
.x18yso3y:not(#\#):not(#\#){transition:box-shadow.15s ease-in-out 0s}
.x6i8iok:focus-within:not(#\#):not(#\#){border-color:#27282b}
@media screen and (min-width: 700px){.x1bk0c53.x1bk0c53:not(#\#):not(#\#){border-bottom:none}}
@media screen and (min-width: 700px){.xdi535z.xdi535z:not(#\#):not(#\#){border-right:1px solid #303236}}
@media screen and (min-width: 700px){.x1qay5qk.x1qay5qk:not(#\#):not(#\#){gap:8px}}
@media screen and (min-width: 700px){.xdybhxf.xdybhxf:not(#\#):not(#\#){grid-template-areas:"meta image"}}
.x1i10hfl:not(#\#):not(#\#):not(#\#){-webkit-tap-highlight-color:transparent}
.x6s0dn4:not(#\#):not(#\#):not(#\#){align-items:center}
.x7a106z:not(#\#):not(#\#):not(#\#){align-items:start}
.xjyslct:not(#\#):not(#\#):not(#\#){-webkit-appearance:none;-moz-appearance:none;appearance:none}
.x1r1mewj:not(#\#):not(#\#):not(#\#){background-color:#000}
.xjbqb8w:not(#\#):not(#\#):not(#\#){background-color:transparent}
.x1oan2z0:not(#\#):not(#\#):not(#\#){background-color:var(--x12r3x38)}
.x6ub06s:not(#\#):not(#\#):not(#\#){background-color:var(--xo8e344)}
.xd6k8oo:not(#\#):not(#\#):not(#\#){box-shadow:rgb(0 0 0 / 10%) 0 2px 4px}
.xntwwlm:not(#\#):not(#\#):not(#\#){color-scheme:dark}
.x1heor9g:not(#\#):not(#\#):not(#\#){color:inherit}
.x11trr7p:not(#\#):not(#\#):not(#\#){color:var(--x1ros7t5)}
.x19evplg:not(#\#):not(#\#):not(#\#){color:var(--x22fgde)}
.x1izhsip:not(#\#):not(#\#):not(#\#){-moz-column-gap:1rem;column-gap:1rem}
.x1ypdohk:not(#\#):not(#\#):not(#\#){cursor:pointer}
.x1lliihq:not(#\#):not(#\#):not(#\#){display:block}
.x78zum5:not(#\#):not(#\#):not(#\#){display:flex}
.xrvj5dj:not(#\#):not(#\#):not(#\#){display:grid}
.x3nfvp2:not(#\#):not(#\#):not(#\#){display:inline-flex}
.x1s85apg:not(#\#):not(#\#):not(#\#){display:none}
.xdt5ytf:not(#\#):not(#\#):not(#\#){flex-direction:column}
.x1q0g3np:not(#\#):not(#\#):not(#\#){flex-direction:row}
.x1iyjqo2:not(#\#):not(#\#):not(#\#){flex-grow:1}
.x2lah0s:not(#\#):not(#\#):not(#\#){flex-shrink:0}
.x1a02dak:not(#\#):not(#\#):not(#\#){flex-wrap:wrap}
.xgcpwm9:not(#\#):not(#\#):not(#\#){font-family:system-ui}
.x1dcheo9:not(#\#):not(#\#):not(#\#){font-size:.8125rem}
.x6u19be:not(#\#):not(#\#):not(#\#){font-size:.9375rem}
.x1c3i2sq:not(#\#):not(#\#):not(#\#){font-size:1.125rem}
.xk50ysn:not(#\#):not(#\#):not(#\#){font-weight:500}
.x4tlyms:not(#\#):not(#\#):not(#\#){grid-template-columns:repeat(auto-fill,min-content)}
.x14ou6l2:not(#\#):not(#\#):not(#\#){grid-template-rows:min-content 1fr}
.xl56j7k:not(#\#):not(#\#):not(#\#){justify-content:center}
.x13a6bvl:not(#\#):not(#\#):not(#\#){justify-content:flex-end}
.xwn7fz2:not(#\#):not(#\#):not(#\#){line-height:1.3}
.xo5v014:not(#\#):not(#\#):not(#\#){line-height:1}
.x1n2onr6:not(#\#):not(#\#):not(#\#){position:relative}
.x1w6r25c:not(#\#):not(#\#):not(#\#){row-gap:1rem}
.x87ps6o:not(#\#):not(#\#):not(#\#){-webkit-user-select:none;-moz-user-select:none;user-select:none}
.xxymvpz:not(#\#):not(#\#):not(#\#){vertical-align:middle}
.x9m8cxw:focus-within:not(#\#):not(#\#):not(#\#){box-shadow:rgb(0 0 0 / 20%) 0 4px 24px}
.x5kezjm:hover:not(#\#):not(#\#):not(#\#){background-color:rgb(45,47,54)}
@media screen and (min-width: 700px){.x1wahavh.x1wahavh:not(#\#):not(#\#):not(#\#){display:block}}
@media screen and (min-width: 700px){.x2kn3a1.x2kn3a1:not(#\#):not(#\#):not(#\#){flex-direction:column}}
@media screen and (min-width: 700px){.xobafph.xobafph:not(#\#):not(#\#):not(#\#){grid-template-columns:320px 1fr}}
@media screen and (min-width: 400px){.x75wj2q.x75wj2q:not(#\#):not(#\#):not(#\#){grid-template-columns:repeat(auto-fill,minmax(300px,1fr))}}
@media screen and (min-width: 700px){.x176jrlb.x176jrlb:not(#\#):not(#\#):not(#\#){grid-template-rows:initial}}
.x14yqylz:not(#\#):not(#\#):not(#\#):not(#\#){height:100vh;height:100svh}
.x1fgtraw:not(#\#):not(#\#):not(#\#):not(#\#){height:28px}
.xt7dq6l:not(#\#):not(#\#):not(#\#):not(#\#){height:auto}
.xjpr12u:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:2px}
.x1e56ztr:not(#\#):not(#\#):not(#\#):not(#\#){margin-bottom:8px}
.x11yfylt:not(#\#):not(#\#):not(#\#):not(#\#){margin-left:-4px}
.x1ldx8e:not(#\#):not(#\#):not(#\#):not(#\#){margin-right:2px}
.xr1yuqi:not(#\#):not(#\#):not(#\#):not(#\#){margin-top:auto}
.x2lwn1j:not(#\#):not(#\#):not(#\#):not(#\#){min-height:0}
.xz65tgg:not(#\#):not(#\#):not(#\#):not(#\#){min-height:200px}
.xeuugli:not(#\#):not(#\#):not(#\#):not(#\#){min-width:0}
.x1odjw0f:not(#\#):not(#\#):not(#\#):not(#\#){overflow-y:auto}
.xh8yej3:not(#\#):not(#\#):not(#\#):not(#\#){width:100%}
@media screen and (min-width: 700px){.xdtem1r.xdtem1r:not(#\#):not(#\#):not(#\#):not(#\#){padding-bottom:16px}}